import styled from 'styled-components'

const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-family: var(--primary);
  color: ${({white}) => (white ? 'var(--white)' : 'var(--blue)')};
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`

export default Title