import React from "react"
// import React, { useState, useEffect } from "react"
import styled from 'styled-components'

//Firebase
// import firebase from "../firebase/firebase"

//Helpers
import Title from "../helpers/Title"

//Components
import Layout from "../components/Layout"
import Seo from "../components/seo"
import Language from "../components/Language"
// import ItemListMarketplace from "../components/ItemListMarketplace"

//Content
import texts from "../components/Cursos/content"

const Cursos = () => {
  // const [data, setData] = useState([])

  // useEffect(() => {
  //   const loadData = () => {
  //     firebase
  //       .firestore()
  //       .collection("Partners")
  //       .orderBy('name')
  //       .get()
  //       .then(snapshot => {
  //         const Partners = []
  //         snapshot.forEach(item => {
  //           const id = item.id
  //           const data = item.data()
  //           Partners.push({ id, data })
  //         })
  //         setData(Partners)
  //       })
  //   }
  //   loadData()
  // }, [])

  return (
    <Layout>
      <Seo title="Cursos" />
      <Container>
        <Title>{Language(texts).title}</Title>
        <Content>
          {/* {
            data.map((item) => {
              return <ItemListMarketplace
                key={item.id}
                name={item.data.name}
                item={item}
              />
            })
          } */}
        </Content>
      </Container>
    </Layout>
  )
}

export default Cursos

const Container = styled.section`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 2rem;
  margin-bottom: 4rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`